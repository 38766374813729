import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DvNgOkDialogComponent} from '../../../app/core/component/dv-ng-ok-dialog/dv-ng-ok-dialog.component';
import {DvNgRemoveDialogComponent} from '../../../app/core/component/dv-ng-remove-dialog/dv-ng-remove-dialog.component';
import {ErrorServiceX} from '../../../app/core/errors/service/ErrorServiceX';
import {LogFactory} from '../../../app/core/logging/LogFactory';
import {ApplicationPropertyRS} from '../../../app/core/rest-services/applicationPropertyRS.rest';
import {AuthServiceRS} from '../../../authentication/service/AuthServiceRS.rest';
import {SearchRS} from '../../../gesuch/service/searchRS.rest';
import {AbstractAdminViewX} from '../../abstractAdminViewX';
import {ReindexRS} from '../../service/reindexRS.rest';
import {ConfigurableEinstellung} from '../EinstellungConfigurations';
import {TSApplicationProperty} from '../TSApplicationProperty';
import {TSApplicationPropertyKey} from '../TSApplicationPropertyKey';

const LOG = LogFactory.createLog('AdminViewXComponent');

@Component({
    selector: 'dv-admin-view-x',
    templateUrl: './admin-view-x.component.html',
    styleUrls: ['./admin-view-x.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AdminViewXComponent extends AbstractAdminViewX implements OnInit {
    @ViewChild(NgForm) public form: NgForm;

    @ViewChild(MatSort, {static: true}) public sort: MatSort;

    protected readonly Date = Date;
    public displayedCollection: MatTableDataSource<TSApplicationProperty>;
    public displayedColumns: string[] = ['name', 'value', 'timestampErstellt'];
    public reindexInProgress: boolean = false;
    public recreateAlleFaelleInProgress: boolean = false;
    public changedApplicationProperties: ConfigurableEinstellung[] = [];

    public constructor(
        private readonly applicationPropertyRS: ApplicationPropertyRS,
        private readonly reindexRS: ReindexRS,
        private readonly searchRS: SearchRS,
        private readonly dvDialog: MatDialog,
        private readonly cd: ChangeDetectorRef,
        authServiceRS: AuthServiceRS,
        private readonly errorService: ErrorServiceX
    ) {
        super(authServiceRS);
    }

    public ngOnInit(): void {
        this.resetForm();
    }

    public submit(): void {
        if (this.form.invalid) {
            return;
        }

        const index = this.changedApplicationProperties?.findIndex(
            einstellung => einstellung.key === 'STADT_BERN_ASIV_CONFIGURED'
        );

        if (
            index >= 0 &&
            this.changedApplicationProperties[index].value === 'true'
        ) {
            this.dvDialog
                .open(DvNgRemoveDialogComponent, {
                    data: {
                        title: 'CREATE_MASSENMUTATION_BERN_DIALOG_TITLE',
                        text: 'CREATE_MASSENMUTATION_BERN_DIALOG_TEXT'
                    }
                })
                .afterClosed()
                .subscribe(
                    res => {
                        if (!res) {
                            return;
                        }
                        this.doSave();
                    },
                    err => {
                        LOG.error(err);
                    }
                );
        } else {
            this.doSave();
        }
    }

    private doSave(): void {
        Promise.all(
            this.changedApplicationProperties.map(
                (value: ConfigurableEinstellung) => {
                    const appProperty = new TSApplicationProperty();
                    appProperty.value = value.value;
                    appProperty.name = value.key as TSApplicationPropertyKey;

                    // testen ob aktuelles property schon gespeichert ist
                    if (appProperty.isNew()) {
                        return this.applicationPropertyRS.update(
                            appProperty.name,
                            appProperty.value
                        );
                    } else {
                        return this.applicationPropertyRS.create(
                            appProperty.name,
                            appProperty.value
                        );
                    }
                }
            )
        ).then(() => {
            this.errorService.addMesageAsInfo('SPEICHERN_ERFOLGREICH');
        });

        this.changedApplicationProperties = [];
    }

    public resetForm(): void {
        this.changedApplicationProperties = [];
        this.applicationPropertyRS
            .getAllApplicationProperties()
            .then(response => {
                this.displayedCollection =
                    new MatTableDataSource<TSApplicationProperty>(response);
                this.displayedCollection.sort = this.sort;
                this.cd.markForCheck();
            });
    }

    public startReindex(): void {
        // avoid sending double by keeping it disabled until reload
        this.reindexInProgress = true;
        this.reindexRS.reindex().subscribe(response => {
            this.dvDialog.open(DvNgOkDialogComponent, {
                data: {title: response}
            });
        });
    }

    public startRecreateAlleFaelleView(): void {
        // avoid sending double by keeping it disabled until reload
        this.recreateAlleFaelleInProgress = true;
        this.searchRS.recreateAlleFaelleView().subscribe(response => {
            this.dvDialog.open(DvNgOkDialogComponent, {
                data: {title: response}
            });
        });
    }

    public doFilter(value: string): void {
        this.displayedCollection.filter = value;
    }

    public updateApplicationProperty(
        applicationProperty: ConfigurableEinstellung
    ): void {
        const index = this.changedApplicationProperties.findIndex(
            changed => changed.key === applicationProperty.key
        );
        if (index >= 0) {
            this.changedApplicationProperties[index].value =
                applicationProperty.value;
        } else {
            this.changedApplicationProperties.push(applicationProperty);
        }
    }
}
